import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'

import Sms from '../components/Index/MainScreen/Desktop/Sms'
import './winners-page.scss'
import Title from '../components/Title/Title'
import PopupDownload from '../components/PopupDownload/PopupDownload'
import StickyFooterMarquee from '../components/Download/StickyFooterMarquee'
import WinnersList from '../components/LandingPage/WinnersList'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import {
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'

const Winners: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "winners-page/banner-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1312, layout: FULL_WIDTH)
        }
      }
      bannerImage: file(relativePath: { eq: "winners-page/banner-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 604, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const handlePlay = () => {
    setIsPopupOpen(true)
  }

  const popupCode = isPopupOpen ? (
    <PopupDownload
      pokerLink={pokerOneLinksStaticPage}
      rummyLink={rummyOneLinksStaticPage}
      close={() => {
        setIsPopupOpen(false)
      }}
      gameName="Winners"
    />
  ) : null

  return (
    <>
      <Layout
        pokerOneLink={pokerOneLinksStaticPage}
        rummyOneLink={rummyOneLinksStaticPage}
      >
        <div className="winners-top-cta">
          <Container>
            <Row>
              <Col className="cta-text">
                <StickyFooterMarquee
                  marqueeText={
                    <>
                      <span>
                        Play Poker & Get ₹17,000 Welcome Bonus. Use Code:
                        <strong>JONTYRHODES</strong>
                      </span>
                    </>
                  }
                />
              </Col>
              <Col className="cta-button">
                <button type="button" onClick={handlePlay}>
                  Signup Now
                </button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="winners-full-banner">
          <GatsbyImage
            image={data.banner.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="winners list"
          />
          <div className="full-banner-content">
            <Container>
              <Row>
                <Col xs={6} lg={5} className="banner-texts">
                  <Sms
                    title="Mega Winners List"
                    hideFeatures
                    hideButtonRating
                  />
                </Col>
                <Col xs={6} lg={7} className="banner-image">
                  <GatsbyImage
                    image={data.bannerImage.childImageSharp.gatsbyImageData}
                    loading="eager"
                    alt="winners list"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="winners-page-content">
          <Container>
            <Title>
              Mega <span className="underline">Winners</span> List
            </Title>
            <div className="winners-page-data">
              <WinnersList />
            </div>
            <button type="button" onClick={handlePlay}>
              Play Now
            </button>
          </Container>
        </div>
        {popupCode}
      </Layout>
      <SEO
        title="Mega Winners: Weekly Poker And Rummy Games Winners"
        description="Check out the skilled and lucky winners of Mega's weekly leaderboard. Join the app to join the winner's list by playing Rummy &amp; Poker."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
      />
    </>
  )
}

export default Winners
